<template>
	<div id="app" scroll="no">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</div>
</template>

<script>
import VConsole from 'vconsole';
import userStore from '@/store/user';
import { mapState } from 'pinia';

export default {
	name: 'App',
	data() {
		return {
			showLogo: true
		};
	},
	computed: {
		...mapState(userStore, ['userInfo'])
	},
	// o6wx85uU5A1KmiswAeuCZ7NOrxxo 客户-赵盟-赵盟
	// o6wx85maxpx6Y1TO93xPlUzC2BrQ  医生-何医生-赵盟
	created() {
		// new VConsole({ theme: 'dark' });
		// if (this.userInfo.customerId == 469||this.userInfo.customerId == 411 ||this.userInfo.customerId == 14772||this.userInfo.sessionId === 'o6wx85vTmzrmt4NyZufBkM550Xrs' || this.userInfo.sessionId === 'o6wx85h9Ftz2doDyEesMIiMleQpA'|| this.userInfo.sessionId === 'o6wx85hDNx0FEm44aJsytaSH62rE'|| this.userInfo.sessionId === 'o6wx85lhhQlSaqGFuD6_x94X8KBU') {
		//         new VConsole({ theme: 'dark' });
		// }
	},
	methods: {}
};
</script>

<style>
#app {
	overflow-x: hidden;
}

:-webkit-scrollbar {
	height: 0 !important;
	width: 0 !important;
	color: transparent !important;
	display: none !important;
}

::-webkit-scrollbar {
	height: 0 !important;
	width: 0 !important;
	color: transparent !important;
	display: none !important;
}

router-view:-webkit-scrollbar {
	height: 0 !important;
	width: 0 !important;
	color: transparent !important;
	display: none !important;
}

router-view::-webkit-scrollbar {
	height: 0 !important;
	width: 0 !important;
	color: transparent !important;
	display: none !important;
}

.icon0 {
	opacity: 0.5;
}
</style>
