import Vue from 'vue';
import Router from 'vue-router';

// 重复路由报错修复
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};

// 安装路由
Vue.use(Router);
// 需要返回回到原来位置的页面
const arr = ['fistPage', 'orderList', 'customerList', 'customerData', 'checkList', 'commuicationList', 'newRegistrant'];
// 配置导出路由
const router = new Router({
	routes: [
		{
			path: '/auth',
			name: 'Auth',
			component: () => import('../views/Auth/index.vue'),
			meta: {
				scrollTop: 0
			}
		},
		{
			// 路由路径
			path: '/login',
			// 路由名字
			name: 'Login',
			// 跳转的组件
			component: () => import('../views/Login'),
			meta: {
				scrollTop: 0
			}
		},
		{
			path: '/Pay',
			name: 'Pay',
			component: () => import('../views/pay.vue'),
			meta: {
				scrollTop: 0
			}
		},
		{
			path: '/customer-registry',
			name: 'customerRegistry',
			component: () => import('../views/CustomerRegistry.vue'),
			meta: {
				title: '客户注册',
				scrollTop: 0
			}
		},
		{
			// 注意'/UserManaga' 后面带 / ，否则不生效
			path: '/shoppingMall',
			// name可不写
			// name: 'shoppingMall',
			component: () => import('../views/ShoppingMall'),
			redirect: '/shoppingMall/fistPage',
			meta: {
				title: '商城',
				scrollTop: 0
			},
			// 子路由
			children: [
				{
					name: 'fistPage',
					path: 'fistPage',
					component: () => import('../views/customer/FirstPage/index.vue'),
					meta: {
						keepAlive: true,
						scrollTop: 0
					}
				},
				{
					name: 'shopCart',
					path: 'shopCart',
					component: () => import('../views/customer/shopCart'),
					meta: {
						scrollTop: 0
					}
				},
				{
					name: 'my',
					path: 'my',
					component: () => import('../views/Personal/my.vue'),
					meta: {
						scrollTop: 0
					}
				},
				{
					name: 'consultMy',
					path: 'consultMy',
					component: () => import('../views/Personal/consultMy.vue'),
					meta: {
						scrollTop: 0
					}
				},
				{
					// 医生的个人中心
					name: 'doctorMy',
					path: 'doctorMy',
					component: () => import('../views/Personal/doctorMy.vue'),
					meta: {
						scrollTop: 0
					}
				},
				{
					path: 'order-list',
					name: 'orderList',
					component: () => import('../views/OrderList/index.vue'),
					meta: {
						title: '订单列表',
						scrollTop: 0
					}
				},
				{
					path: 'customer-list', // 客户管理-列表
					name: 'customerList',
					component: () => import('../views/CustomerMange/List/index.vue'),
					meta: {
						keepAlive: true,
						scrollTop: 0
					}
				},
				{
					path: 'customerData',
					name: 'customerData',
					component: () => import('../views/CustommerData/List/index.vue'),
					meta: {
						title: '客户数据-列表',
						scrollTop: 0
					}
				},
				{
					path: 'commuication-list',
					name: 'commuicationList',
					component: () => import('../views/CustomerMange/Detail/conponents/CommunicationRecord.vue'),
					meta: {
						title: '沟通记录列表',
						scrollTop: 0
					}
				},
				{
					path: 'history-compare-list',
					name: 'historyCompare-list',
					component: () => import('../views/HistoricalComparison/index.vue'),
					meta: {
						title: '历史对比列表',
						scrollTop: 0
					}
				}
			]
		},
		{
			path: '/category',
			name: 'category',
			component: () => import('../views/NewLogin/Category'),
			meta: {
				title: '选择身份',
				scrollTop: 0
			}
		},
		{
			path: '/searchTenant',
			name: 'searchTenant',
			component: () => import('../views/searchTenant'),
			meta: {
				title: '选择机构',
				scrollTop: 0
			}
		},
		{
			path: '/searchDepartment',
			name: 'searchDepartment',
			component: () => import('../views/searchDepartment'),
			meta: {
				title: '选择门店',
				scrollTop: 0
			}
		},
		{
			path: '/putNum',
			name: 'putNum',
			component: () => import('../views/putNum'),
			meta: {
				title: '手机号码绑定',
				scrollTop: 0
			}
		},
		{
			path: '/goods-detail',
			name: 'goodsDetail',
			component: () => import('../views/GoodsDetail/index.vue'),
			meta: {
				title: '商品详情',
				scrollTop: 0
			}
		},
		{
			path: '/order-detail',
			name: 'orderDetail',
			component: () => import('../views/OrderDetail/index.vue'),
			meta: {
				title: '订单详情',
				scrollTop: 0
			}
		},
		{
			path: '/checkReport',
			name: 'checkReport',
			component: () => import('../views/CustomerMange/Detail/conponents/CheckReport.vue'),
			meta: {
				title: '检测报告-列表',
				scrollTop: 0
			}
		},
		{
			path: '/check-detail',
			name: 'checkDetail',
			component: () => import('../views/CheckDetail/index.vue'),
			meta: {
				title: '检测报告详情',
				scrollTop: 0
			}
		},
		{
			path: '/faceModify',
			name: 'faceModify',
			component: () => import('../views/CustomerMange/Detail/conponents/Facemodification.vue'),
			meta: {
				title: '修脸方案-列表',
				scrollTop: 0
			}
		},
		{
			path: '/modify-detail',
			name: 'modifyDetail',
			component: () => import('../views/ModifyDetail/index.vue'),
			meta: {
				title: '修脸方案详情',
				scrollTop: 0
			}
		},
		{
			path: '/doctor-communication',
			name: 'doctor-communication',
			component: () => import('../views/DoctorCommunication/index.vue'),
			meta: {
				title: '医生交流详情',
				scrollTop: 0
			}
		},
		{
			path: '/choose',
			name: 'choose',
			component: () => import('../views/CheckDetail/choose.vue'),
			meta: {
				title: '选择查看方式',
				scrollTop: 0
			}
		},
		{
			path: '/pdf',
			name: 'pdf',
			component: () => import('../views/CheckDetail/components/pdf.vue'),
			meta: {
				title: '查看pdf',
				scrollTop: 0
			}
		},
		{
			path: '/video',
			name: 'video',
			component: () => import('../views/CheckDetail/components/video.vue'),
			meta: {
				title: '查看视频',
				scrollTop: 0
			}
		},
		{
			path: '/commuication-list',
			name: 'commuicationList',
			component: () => import('../views/CustomerMange/Detail/conponents/CommunicationRecord.vue'),
			meta: {
				title: '沟通记录列表',
				scrollTop: 0
			}
		},
		{
			path: '/commuication-detail',
			name: 'commuicationDetail',
			component: () => import('../views/CommunicationDetail/index.vue'),
			meta: {
				title: '沟通记录详情',
				scrollTop: 0
			}
		},
		{
			path: '/customerDetail',
			name: 'customerDetail',
			component: () => import('../views/CustommerData/DetailByCustomer/index.vue'),
			meta: {
				title: '客户成交详情',
				scrollTop: 0
			}
		},
		{
			path: '/projectDetail',
			name: 'projectDetail',
			component: () => import('../views/CustommerData/DetailByProject/index.vue'),
			meta: {
				title: '项目成交详情',
				scrollTop: 0
			}
		},
		{
			path: '/personal-performance',
			name: 'personalPerformance',
			component: () => import('../views/PersonalPerformance/index.vue'),
			meta: {
				title: '个人业绩',
				scrollTop: 0
			}
		},
		{
			path: '/searchPage',
			name: 'searchPage',
			component: () => import('../views/SearchPage.vue')
		},
		{
			path: '/ranking-list',
			name: 'rankingList',
			component: () => import('../views/RankingList/List/index.vue'),
			meta: {
				title: '近3月合作医院/医生成交排行榜'
			}
		},
		{
			path: '/ranking-detail',
			name: 'rankingDetail',
			component: () => import('../views/RankingList/Detail/index.vue'),
			meta: {
				title: '合作医院/医生成交排行榜-详情'
			}
		},
		{
			path: '/new-registrant',
			name: 'newRegistrant',
			component: () => import('../views/NewRegistrant/index.vue'),
			meta: {
				title: '新客注册数'
			}
		},
		{
			path: '/check-list',
			name: 'checkList',
			component: () => import('../views/CheckList/index.vue'),
			meta: {
				title: '客户检测数'
			}
		},
		{
			path: '/customer-detail', // 客户详情
			name: 'CustomerInfoDetail',
			component: () => import('../views/CustomerMange/Detail/index.vue')
		},
		{
			path: '/seach-teaent', // 查询门店
			name: 'SeachTeaent',
			component: () => import('../views/SeachTeaent/index.vue')
		},
		{
			path: '/modify-info',
			name: 'ModifyInfo',
			component: () => import('../views/ModifyInfo/index.vue')
		},
		{
			path: '/Nothing',
			name: 'Nothing',
			component: () => import('../views/Nothing/index.vue')
		},
		{
			path: '/chose-roles',
			name: 'ChoseRoles',
			component: () => import('../views/ChoseRoles/index.vue'),
			meta: {
				title: '用户角色门店列表',
				scrollTop: 0
			}
		},
		{
			path: '/no-permissions',
			name: 'NoPermissions',
			component: () => import('../views/NoPermissions/index.vue'),
			meta: {
				title: '用户无权限',
				scrollTop: 0
			}
		},
		{
			path: '/no-related',
			name: 'NoRelated',
			component: () => import('../views/NoRelated/index.vue'),
			meta: {
				title: '用户关联账户',
				scrollTop: 0
			}
		},
		// {
		//   path: '/manager-home-page', // 店长首页
		//   name: 'ManagerHomePage',
		//   component: () => import('../views/ManagerHomePage/index.vue'),
		// },
		{
			path: '/apply-refund',
			name: 'applyRefund',
			component: () => import('../views/ApplyRefund/index.vue'),
			meta: {
				title: '申请退款',
				scrollTop: 0
			}
		},
		{
			path: '/detail-refund',
			name: 'detailRefund',
			component: () => import('../views/detailRefund/index.vue'),
			meta: {
				title: '退款详情',
				scrollTop: 0
			}
		},
		{
			path: '/refund-approval',
			name: 'refundApproval',
			component: () => import('../views/RefundApproval/index.vue'),
			meta: {
				title: '退款审批',
				scrollTop: 0
			}
		},
		{
			path: '/pay-order',
			name: 'payOrder',
			component: () => import('../views/PayOrder/index.vue'),
			meta: {
				title: '订单支付',
				scrollTop: 0
			}
		},
		{
			path: '/doctor-setting',
			name: 'doctorsetting',
			component: () => import('../views/DoctorSetting/index.vue'),
			meta: {
				title: '个人中心',
				scrollTop: 0
			}
		},
		{
			path: '/doctor-info',
			name: 'doctorinfo',
			component: () => import('../views/DoctorInfo/index.vue'),
			meta: {
				title: '个人简介',
				scrollTop: 0
			}
		},
		{
			path: '/customer-confirm',
			name: 'customerconfirm',
			component: () => import('../views/CustomerConfirm/index.vue'),
			meta: {
				title: '确认信息',
				scrollTop: 0
			}
		},
		{
			path: '/app-version',
			name: 'appVersion',
			component: () => import('../views/AppVersion/index.vue'),
			meta: {
				title: '视频管理'
			}
		},
		{
			path: '/success',
			name: 'success',
			component: () => import('../views/Success/index.vue'),
			meta: {
				title: '登录成功'
			}
		},
		{
			path: '/competition',
			name: 'competition',
			component: () => import('../views/competition/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/competitionForm',
			name: 'competitionForm',
			component: () => import('../views/competitionForm/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/competitionSuccess',
			name: 'competitionSuccess',
			component: () => import('../views/competitionSuccess/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/charts',
			name: 'charts',
			component: () => import('../views/Charts/index.vue'),
			meta: {
				title: '登录成功'
			}
		},
		{
			path: '/propagate',
			name: 'propagate',
			component: () => import('../views/propagate/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/invitation',
			name: 'invitation',
			component: () => import('../views/invitation/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/mbindex',
			name: 'mbindex',
			component: () => import('../views/MbIndex/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/viewVideo',
			name: 'Video',
			component: () => import('../views/Video/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/MiniApp',
			name: 'MiniApp',
			component: () => import('../views/MiniApp/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/Mingmen',
			name: 'Mingmen',
			component: () => import('../views/Mingmen/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/judgeLogin',
			name: 'judgeLogin',
			component: () => import('../views/judge/login.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/judgeList',
			name: 'judgeList',
			component: () => import('../views/judge/list.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/judgeScore',
			name: 'judgeScore',
			component: () => import('../views/judge/score.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/DigitalHumans',
			name: 'DigitalHumans',
			component: () => import('../views/DigitalHumans/index.vue'),
			meta: {
				title: ''
			}
		}
	]
});

router.beforeEach((to, from, next) => {
	// 保存滚动位置
	if (from && arr.includes(from.name)) {
		if (from.name === 'checkList' || from.name === 'newRegistrant') {
			const $content2 = document.querySelector('.list-content');
			const scrollTop2 = $content2 ? $content2.scrollTop : 0;
			console.log('333333', scrollTop2);
			localStorage.setItem('scolltop', $content2.scrollTop);
			from.meta.scrollTop2 = scrollTop2;
		} else {
			const $content = document.querySelector('.list-scroll');
			const scrollTop = $content ? $content.scrollTop : 0;
			console.log('ssssssss', $content.scrollTop);
			localStorage.setItem('scolltop', $content.scrollTop);
			from.meta.scrollTop = scrollTop;
		}
	}
	next();
});

export default router;
